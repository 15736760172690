<script>
import LayoutLoose from "@/router/layouts/LayoutLoose";
import appConfig from "@/app.config";

import {mapState} from "vuex";
import {HalfCircleSpinner} from "epic-spinners";

export default {
  page: {
    title: "Server",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    LayoutLoose,
    Skeleton: () => import('@/components/lazy/servers/view/Skeleton')
  },
  data() {
    return {
      server_id: this.$route.params.server_id,
      component: this.$route.params.component,
    }
  }
};
</script>

<template>
  <LayoutLoose>
    <Skeleton :server_id="server_id" :component="component" />
  </LayoutLoose>
</template>