<script>
import router from "@/router";
import { layoutComputed } from "@/state/helpers";

import NavBar from "@/components/nav-bar";
import SideBar from "@/components/side-bar";
import RightBar from "@/components/right-bar";
import Footer from "@/components/footer";

/**
 * Vertical layout
 */
export default {
  components: { NavBar, SideBar, RightBar, Footer },
  data() {
    return {
      isMenuCondensed: false,
      layout: this.$store ? this.$store.state.layout.layoutType : null || null
    };
  },
  computed: {
    ...layoutComputed
  },
  created() {
    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-layout-size", "boxed");
    this.onReady();
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      this.$refs.notificationsBar.onShow();
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
    onReady() {
    }
  }
};
</script>

<template>
  <div id="layout-wrapper">
    <NavBar />
    <SideBar :is-condensed="isMenuCondensed" />
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="main-content">
      <slot />
      <!-- <div class="page-content min-vh-100"></div> -->
      <Footer />
    </div>
    <RightBar ref="notificationsBar" />
  </div>
</template>
