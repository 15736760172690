<script>
import { layoutComputed } from "@/state/helpers";
import VerticalLoose from "@/router/layouts/VerticalLoose";

export default {
  components: { VerticalLoose },
  data() {
    return {};
  },
  computed: {
    ...layoutComputed
  },
  methods: {}
};
</script>

<template>
  <div>
    <VerticalLoose :layout="layoutType">
      <slot />
    </VerticalLoose>
  </div>
</template>
